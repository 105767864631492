html {
  text-align: center;
  font-family: 'Mortal Kombat 2', sans-serif;
}
body {
  max-width: 1680px;
  margin: auto;
}

input {
  /*height: 4vh;*/
  border: 4px solid #c1c1c1;
  font-family: 'Mortal Kombat 2', sans-serif;
  min-width: 65vw;
  font-size: calc(8px + 2vmin);
  padding: 8px;
}

ul {
  list-style-type: square;
}

ul li {
  text-align: left;
}

.green {
  color: green !important;
}

.red {
  color: red !important;;
}

.gray {
  color: #c1c1c1 !important;;
}

.App {
  text-align: center;
  font-family: 'Mortal Kombat 2', sans-serif;
}

.App-header {
  /*background-color: #282c34;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-body {
  position: relative;
  width: calc(100% - 16px);

  background-image: url("../public/images/main-background.png");
  background-color: #252525;
  background-position: center;
  min-height: 100vh;
  padding-top: 10vh;
  /*display: flex;*/
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
  padding: 8px;

  background-size: cover;
  background-position-x: 47%;
}

@media only screen and (max-width: 800px) {
  .App-body {
    background-position-x: 47%;
  }
}


/*fighters*/

.fighter-stats {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.fighter-wins {
  width: calc(30% - 16px);
  display: inline-block;
  text-align: left;
  padding-left: 16px;
  padding-top: 1vh;
}

.fighter-value {
  width: calc(70% - 0px);
  display: inline-block;
  text-align: right;
  padding-top: 1vh;
}

.fighter-value > span {
  padding: 2px;
  background-color: #c1c1c1;
  opacity: 80%;
  color: black;
}

.fighters-grid {
  /*border-bottom: 1px solid #c1c1c1;*/
  padding-bottom: 20px;
  position: relative;
}

.fighters-grid:before {
  content : "";
  position: absolute;
  left    : 25%;
  bottom  : 0;
  height  : 1px;
  width   : 50%;  /* or 100px */
  border-bottom:1px solid #c1c1c1;
}

.fighters {
  z-index: 2;
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.explosion {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;


  background-image: url("../public/images/explosion.png");
  background-size: 0%;
  background-repeat: no-repeat;
  background-position: center;

  animation-name: explode;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-timing-function: step-end;

}

@keyframes explode {
  0% {background-size: 10%;}
  10% {background-size: 15%;}
  20% {background-size: 20%;}
  30% {background-size: 30%;}
  40% {background-size: 40%;}
  50% {background-size: 60%;}
  60% {background-size: 80%;}
  70% {background-size: 110%;}
  80% {background-size: 120%;}
  90% {background-size: 150%;}
  100% {background-size: 200%;}
}

@media only screen and (max-width: 800px) {
  @keyframes explode {
    0% {background-size: 10%;}
    10% {background-size: 15%;}
    20% {background-size: 20%;}
    30% {background-size: 30%;}
    40% {background-size: 40%;}
    50% {background-size: 60%;}
    60% {background-size: 100%;}
    70% {background-size: 150%;}
    80% {background-size: 200%;}
    90% {background-size: 250%;}
    100% {background-size: 300%;}
  }
}

.fighter-wrapper > div{
  border: 4px solid #c1c1c1;
  height: 25vh;
  cursor: pointer;
  position: relative;
}

.fighter-active > .fighter-wrapper > div:first-child {
  border: 4px solid green !important;
}

.fighter-vs > .fighter-wrapper > div:first-child {
  border: 4px solid yellow;
  animation: blink .2s step-end infinite alternate;
}

@keyframes blink { 50% { border-color: #c1c1c1 ; }  }


.fighter-bio {
  margin-top: 1vh !important;
  /*height: 120px;*/
  text-align: justify;
}

.fighter-wrapper {
  background-image: url("../public/backgroung.jpeg") ;
}

/*inside the fight screen*/
.fighter-card {
  /*height: 50vh;*/
  /*border-radius: 8px;*/
  /*border: 2px solid #c1c1c1;*/
}

.fighter-card-short {
  /*height: 35vh;*/
}

.selection-header {
  height: 10vh;
  color: yellow;
  font-size: calc(10px + 2vmin);
  display: table;
  padding: 3vh;
}

@media only screen and (max-width: 800px) {
  .selection-header {
    height: 5vh;
  }

  .fighter-wrapper > div{
    height: 22vh;
  }
}


.fighter-weapons {
  text-align: center;
  margin-top: 1vh !important;
  color: yellow;
}

.fighter-weapon {
  border: 4px solid #c1c1c1;
  color: white;
  text-decoration: none;
}

.fighter-weapon-blink {
  border: 4px solid yellow;
  color: yellow;
  animation: blink .2s step-end infinite alternate;
}

.share-social div {
  padding: 0;
  background-color: transparent;
  max-width: 100%;
  width: 100%;
  text-align: center;
  display: inline;
  font-family: 'Mortal Kombat 2', sans-serif;
}

.share-social > div > div:last-child  > div:last-child {
  padding-top: 1vh;
}
.share-social > div > div:last-child  > div:first-child {
  display: none;
}

.share-social > div > div:last-child {
  /*display: none;*/
  border: 0;
}

.share-social svg {
  border: 4px solid #c1c1c1;
}

.share-social circle {
  fill: black;
}

button.fighter-weapon {
  /*border: 0 none !important;*/
  /*position: absolute;*/
  /*right: 0;*/
}

.fighter-weapon svg {
  height: 1.9vh;
}

.fighter-weapon-active {
  border: 4px solid green !important;
  color: green;
}
.fighter-weapon, .fighter-weapon-active {
  display: inline-block;
  margin: 8px;
  padding: 8px;
  cursor: pointer;
  background-color: transparent;
  font-family: 'Mortal Kombat 2', sans-serif;
  font-size: calc(8px + 2vmin);
}

div#cramer {
  background-image: url("../public/images/fighters/cramer/cramer-8bit.png") ;
  background-size: cover;
}

div#cramer.wins {
  background-image: url("../public/images/fighters/cramer/cramer-wins-8bit.png") ;
}

div#buffett {
  background-image: url("../public/images/fighters/buffett/buffett-8bit.png") ;
  background-size: cover;
}

div#buffett.wins {
  background-image: url("../public/images/fighters/buffett/buffett-wins-8bit.png");
}

div#pelosi {
  background-image: url("../public/images/fighters/pelosi/pelosi-8bit.png") ;
  background-size: cover;
}

div#pelosi.wins {
  background-image: url("../public/images/fighters/pelosi/pelosi-wins-8bit.png") ;
}

div#wood {
  background-image: url("../public/images/fighters/wood/wood-8bit.png") ;
  background-size: cover;
}

div#wood.wins {
  background-image: url("../public/images/fighters/wood/wood-wins-8bit.png") ;
}

div#wsb {
  background-image: url("../public/images/fighters/wsb/wsb-8bit.png") ;
  background-size: cover;
}

div#wsb.wins {
  background-image: url("../public/images/fighters/wsb/wsb-wins-8bit.png") ;
}

div#wsb.loses {
  background-image: url("../public/images/fighters/wsb/wsb-loses-8bit.png") ;
}

div#pandemic {
  background-image: url("../public/pandemic-8bit.png") ;
  background-size: cover;
}

div#postpandemic {
  background-image: url("../public/postpandemic-8bit.png") ;
  background-size: cover;
}

div#housingcrash {
  background-image: url("../public/housingcrash-8bit.png") ;
  background-size: cover;
}

div#newworld {
  background-image: url("../public/newworld-8bit.png") ;
  background-size: cover;
}

/*fighters-vs*/
.fighters-vs {
  z-index: 1;
}

.fighters-vs > div{
  position: absolute;
  bottom: 0px;
  width: 30vw;
  height: 30vw;
}

.fighters-vs > div:first-child{
  left: 0;
}

.fighters-vs > div:last-child{
  right: 0;
}

/*Cathie Wood*/
div#wood-pointing-lr {
  background-image: url("../public/images/fighters/wood/wood-lr-8bit.png") ;
  background-size: cover;
}

div#wood-pointing-rl {
  background-image: url("../public/images/fighters/wood/wood-rl-8bit.png") ;
  background-size: cover;
}

div#wsb-pointing-lr {
  background-image: url("../public/images/fighters/wsb/wsb-pointing-lr-8bit.png") ;
  background-size: cover;
}

div#wsb-pointing-rl {
  background-image: url("../public/images/fighters/wsb/wsb-pointing-rl-8bit.png") ;
  background-size: cover;
}

div#cramer-pointing-lr {
  background-image: url("../public/images/fighters/cramer/cramer-pointing-lr-8bit.png") ;
  background-size: cover;
}

div#cramer-pointing-rl {
  background-image: url("../public/images/fighters/cramer/cramer-pointing-rl-8bit.png") ;
  background-size: cover;
}

div#buffett-pointing-lr {
  background-image: url("../public/images/fighters/buffett/buffett-pointing-lr-8bit.png") ;
  background-size: cover;
}

div#buffett-pointing-rl {
  background-image: url("../public/images/fighters/buffett/buffett-pointing-rl-8bit.png") ;
  background-size: cover;
}

div#pelosi-pointing-lr {
  background-image: url("../public/images/fighters/pelosi/pelosi-pointing-lr-8bit.png") ;
  background-size: cover;
}

div#pelosi-pointing-rl {
  background-image: url("../public/images/fighters/pelosi/pelosi-pointing-rl-8bit.png") ;
  background-size: cover;
}

@media only screen and (max-width: 600px) {
  div[id*=pointing] {
    display: none;
  }

  div#pelosi, div#wsb, div#cramer, div#buffett  {
    background-position-x: 60%;
  }
}